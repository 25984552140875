* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.question-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 15px;
    padding-top: 30px;
    width: 1200px;
    background-color: rgb(196, 195, 241);
    border-radius: 20px;
    margin-bottom: 40px;
}

.btn {
    border: 2px solid black;
    width: 300px;
    height: 50px;
    cursor: pointer;

}

.btn:hover {
    background-color: indigo;
    color: wheat;
}
.options {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
}