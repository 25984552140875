.quiz-type{
    font-size: large;
    font-family: 'Montserrat', sans-serif;
    flex-direction: row;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
form {
    height: 100px;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    flex-direction: row;
}
.child {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}
span {
    padding-right: 3px;
}
input  {
    padding-right: 10px;
    width: 100px;
    height: 40px;
    border: 1px solid black;
    font-size: 15px;
}
select {
    width: 200px;
    height: 40px;
    border: 1px solid black;
    font-size: 15px;
}

.submitbutton {
    width: 100px;
    height: 40px;
    border: 2px solid black;
    background-color: green;
    color: white;
    font-size: 15px;
    cursor: pointer;
}
.submitbutton:hover {
    background-color: tomato;
    color: black;
}